import { isLocalizedStringDefined, localizedString } from '@languages';
import camelCase from 'lodash/camelCase';

export const getAllowedDocumentsInIdvc = (appConfig) => {
  const { acceptedDocs, acceptedDocCountryCombo } = appConfig.engine4[appConfig.flowType];
  let cardTypes = [localizedString('driverLicence'), localizedString('passport')];

  if (acceptedDocs && acceptedDocs.length > 0) {
    cardTypes = [...cardTypes, acceptedDocs];
  }

  if (acceptedDocCountryCombo && Object.keys(acceptedDocCountryCombo).length > 0) {
    Object.keys(acceptedDocCountryCombo).forEach((key) => {
      if (acceptedDocCountryCombo[key] !== 'NONE') {
        const camelCasedDocKey = camelCase(key);
        const displayCardName = isLocalizedStringDefined(camelCasedDocKey)
          ? localizedString(camelCasedDocKey)
          : key;
        cardTypes.push(displayCardName);
      }
    });
  }

  const isDigitalIdEnabled = !!cardTypes.find((type) => type.match(/digital/i));

  if (isDigitalIdEnabled) {
    cardTypes = cardTypes.filter((type) => !type.match(/digital/i));
  }

  // Unique elements
  return [...new Set(cardTypes)];
};
